import { defineStore } from "pinia";
import type { CardItemT } from "../schemas/schemas";
import { mapToCardItem } from "../composables/common";
import {
  AuthorT,
  CatalogItemT,
  JournalItemT,
  NewsItemT,
  ServiceT,
  SouvenirItemT,
} from "../schemas/schemas";

interface SearchResult {
  name: string;
  collection: string;
  slug: string;
}

export const useSearchStore = defineStore("search", {
  state: () => ({
    searchResult: [] as SearchResult[],
  }),
  getters: {
    getSearchResult: (state) => {
      return state.searchResult;
    },
    getSearchResultCardsItem: (state): CardItemT[] => {
      return state.searchResult.map((item: SearchResult) => {
        let photo = null;

        switch (item.collection) {
          case "service":
          case "news_item":
            photo = (item as NewsItemT | ServiceT)?.preview_image;
            break;
          case "author":
            if ((item as AuthorT).preview_image?.data) {
              photo = (item as AuthorT)?.preview_image;
            } else {
              photo = (item as AuthorT).detail_image;
            }
            break;
          case "catalog_item":
          case "souvenir_item":
            photo = (item as CatalogItemT | SouvenirItemT).photo;
            break;
          case "exhibition":
          case "journal":
          case "journal_article":
            photo = (item as JournalItemT).cover;
            break;
        }

        const cardItem = mapToCardItem(item, item.collection);

        cardItem.photo = photo;

        return cardItem;
      });
    },
  },
  actions: {
    setSearchResult(items: SearchResult[]) {
      this.searchResult = items;
    },
  },
});
